import * as React from "react"
import { graphql } from 'gatsby'
import SbEditable from 'storyblok-react'

import Layout from "../components/layout"
import Seo from "../components/seo"
import DynamicComponent from "../components/dynamicComponent"
import useStoryblok from "../lib/storyblok"

const TalkPage = ({ data, location }) => {
  let story = data.storyblokEntry
  story = useStoryblok(story, location)

  const components = story.content.body.map(blok => {
    return (<DynamicComponent blok={blok} key={blok._uid} />)
  })

  return (
  <Layout>
    <SbEditable content={story.content}>
      <Seo
        title="Talk"
        description="I'm a DevRel Engineer at Storyblok. Also, I organize a free tech skill and knowledge-sharing community, Lilac."
        image={ data.site.image }
      />
        { components }
    </SbEditable>
  </Layout>
)}

export default TalkPage

export const query = graphql`
  query TalkQuery {
    storyblokEntry(full_slug: {eq: "talk/"}) {
      content
      name
    }
    site {
      siteMetadata {
        image
      }
    }
  }
`